import cx from 'classnames';
import { graphql, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import React, { ReactElement, useEffect } from 'react';
import PageFooterStudio from '../components/pageFooter/pageFooterStudio';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import { useScreen } from '../hooks/useScreen';
import { useMdData } from '../static-queries/useBlogData';
import blogTemplateStyles from './studio.module.scss';
// import { CommentCount, Disqus } from 'gatsby-plugin-disqus';

export default function ProjectTemplate({
  data,
}: PageProps<{
  site: any,
  markdownRemark: any,
}>): ReactElement {
  const md = data.markdownRemark;
  const blogData = useMdData();
  const allBlogData = blogData.posts.edges;

  const {screen} = useScreen();
  const {setIsSideBarShown, setPageType} = usePageContext();

  useEffect(() => {
    setPageType('studio');
    setIsSideBarShown(true);
    return () => {};
  }, []);

  function getNextSlug(slug: string) {
    const allSlugs = allBlogData.map((blog: any) => blog.node.fields.slug);
    const nextSlug = allSlugs[allSlugs.indexOf(slug) + 1];
    if (nextSlug !== undefined && nextSlug !== '') {
      return nextSlug;
    }
    return allSlugs[0];
  }

  const nextSlug = getNextSlug(md.fields.slug);

  const renderContent = (content: string) => {
    return content.replace(/<!-- cv-start -->([\s\S]*?)<!-- cv-end -->/gm, ''); // Return the full content for CV page or default case
  };

  // let disqusConfig = {
  //   url: `${config.siteUrl+location.pathname}`,
  //   identifier: md.frontmatter.title,
  //   title: md.frontmatter.title,
  // }
  return (
    <>
      <SEO
        title={md.frontmatter.title}
        description={md.excerpt}
        rrssImg={
          md.frontmatter.hero_image
            ? md.frontmatter.hero_image.publicURL || md.frontmatter.hero_image
            : ''
        }
      />
      <div className={blogTemplateStyles.backgroundProject}></div>

      <article
        className={cx(
          'w-full md:px-6 pb-6 min-h-screen flex items-center flex-col',
          blogTemplateStyles.mainContainer
        )}>
        {/* <div
          className={
            'hidden md:block w-full'
          }>
          <CatList />
        </div> */}
        <section className="md:w-basic w-full md:mt-0 mt-20">
          <div
            className={cx(
              blogTemplateStyles.blog,
              'pb-20 md:pt-10 flex items-center flex-col'
            )}>
            <div
              className={cx(
                blogTemplateStyles.blog__info,
                'justify-center flex flex-col md:px-12 md:py-10 p-5'
              )}>
              <h1 className={cx("font-studio text-gray-800 tracking-wider text-2xl md:text-3xl md:leading-10", md.frontmatter.subtitle ? "mb-0 pb-1" : "mb-3")}>
                {md.frontmatter.title}
              </h1>
{/*               {md.frontmatter.subtitle && (
                <h4 className="font-studio mb-3 text-gray-800 tracking-wider text-mb md:text-base md:leading-10">
                  {md.frontmatter.subtitle}
                </h4>
              )} */}
              {/* <div
                className={'flex mb-4'}>
                <div
                  className={cx(
                    blogTemplateStyles.catsContainer,
                    'flex w-full justify-start'
                  )}>
                  {md.frontmatter.categories?.map(
                    (cat: string) => {
                      return (
                        <Category
                          tooltip={
                            md
                              .frontmatter
                              .categories
                              .length >
                            7
                          }
                          key={cat}
                          text={
                            md
                              .frontmatter
                              .categories
                              .length <
                              8 &&
                            screen ==
                              ScreenType.Desktop
                          }
                          category={cat}
                          size={20}
                        />
                      );
                    }
                  )}
                </div>
              </div> */}
              <h3 className="font-base text-sm text-gray-500 tracking-wide">
                {md.frontmatter.date}
              </h3>
            </div>

            {(md.frontmatter.hero_image_studio ||
              (md.frontmatter.hero_image) && (
                <figure className={cx(blogTemplateStyles.blog__hero)}>
                  {md.frontmatter.hero_image?.childImageSharp ? (
                    // Local image
                    <Img
                      fluid={
                        md.frontmatter.hero_image_studio
                          ? md.frontmatter.hero_image_studio.childImageSharp
                              .fluid
                          : md.frontmatter.hero_image.childImageSharp.fluid
                      }
                      alt={md.frontmatter.title}
                      className={cx(
                        blogTemplateStyles.image,
                        'md:rounded-xl md:shadow-md'
                      )}
                    />
                  ) : (
                    // External URL
                    <img
                      src={md.frontmatter.heroImage}
                      alt={md.frontmatter.title}
                      className={cx(
                        blogTemplateStyles.image,
                        'md:rounded-xl md:shadow-md'
                      )}
                    />
                  )}
                </figure>
              ))}
            <div className="mt-10">
              <div
                className={cx(
                  blogTemplateStyles.blog__body,
                  'p-6 md:px-10 pb-14 w-full'
                )}
                dangerouslySetInnerHTML={{
                  __html: renderContent(md.html),
                }}
              />
            </div>
          </div>
          {/* <CommentCount config={disqusConfig} placeholder={'...'} />
          <Disqus config={disqusConfig} /> */}
          <PageFooterStudio />
        </section>
      </article>
    </>
  );
}

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      fields {
        slug
      }
      excerpt
      frontmatter {
        title
        subtitle
        author
        categories
        date(formatString: "MMMM Do, YYYY")
        # hero_image_studio {
        #   childImageSharp {
        #     fluid(maxWidth: 1500) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        #   publicURL
        # }
        hero_image {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
      html
    }
  }
`;
